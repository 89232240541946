footer {
  display: flex;
  padding-top: 50px;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  border-top: 1px solid black;

  .footer--container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .logo {
      margin: 0;
      margin-right: 100px;
      width: 80px;
      height: 100%;

      img {
        width: 100%;
      }
    }

    .social {
      width: 45%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
    }

    .social__container {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      display: flex;
      max-width: 171px;
      height: max-content;

      a {
        display: block;
        max-width: 37px;

        &:last-child {
          max-width: 100%;
          margin-top: 15px;
        }
      }
    }

    .resources,
    .other {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      min-width: 118px;
      margin: 0 45px;

      p,
      a {
        text-transform: uppercase;
        font-weight: 800;
        letter-spacing: 1.2px;
        font-size: 1.2rem;
      }

      p {
        margin: 0;
        border-top: 1px solid black;

        &:first-child {
          color: $tertiaryColour;
          margin-bottom: 10px;
        }
      }
    }

    .resources__links {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      min-height: 85px;

      a {
        margin: 2px 0;
      }

      a:hover {
        color: $primaryColour;
      }
    }
  }

  .copyright {
    width: 100%;
    text-align: center;
    border-top: 1px solid black;
    margin-top: 50px;
    padding: 16px 0;

    p {
      font-weight: 800;
      font-size: 1.2rem;
      margin: 0;
    }

    a:hover {
      color: $primaryColour;
    }
  }
}