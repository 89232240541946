#details {

  .back {
    margin: 0 0 20px 0;

    svg {
      width: 30px;
    }

    a {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 240px;
    }
  }

  &.loading {
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
    min-height: 800px;
    text-align: center;
    letter-spacing: 1.4px;
    padding-top: 220px;
    min-height: 440px;
    margin-bottom: 1000px;
  }

  .quotes__blocks {
    margin: 0;
  }

  #modalUrlText {
    position: absolute;
    left: -9999px;
  }

  h4 {
    display: flex;
    flex-direction: column;
    margin-top: 0;

    span {
      margin: 0 auto;
      display: inline-block;
      border-bottom: 3px solid black;
      line-height: normal;
      padding: 10px 0;
    }

    .subtitle {
      border: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;

      span {
        padding-right: 10px;
        margin: 0;

        &:last-child {
          padding-right: 0;
        }
      }
    }
  }

  .lastUpdate {
    margin: 0;
    margin-top: 30px;
    font-size: 1.4rem;
    text-align: center;
  }

  .title {
    padding: 100px 0;
    border-bottom: 1px solid black;
  }

  .details {
    padding: 100px 0;
  }

  blockquote {
    font-size: 3.8rem;
    font-weight: 800;
    text-transform: uppercase;
    margin: 0 auto;
    margin-bottom: 100px;
  }

  .blockquote--author {
    font-weight: normal;
    text-transform: none;
  }

  .details__view {
    display: flex;

    &>div {
      max-width: 50%;
    }
  }

  .details__view--photo {
    margin-right: 80px;
  }

  .details__view--categories {

    h5,
    p {
      font-size: 1.8rem;
    }

    h5 {
      text-align: left;
      text-transform: uppercase;
      font-weight: 900;
      letter-spacing: 1.8px;
    }

    p {
      margin: 0;
      margin-bottom: 10px;
    }

    a {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 1.3rem;
      letter-spacing: 1.3px;
      text-decoration: underline;

      &:hover {
        color: $primaryColour;
      }
    }
  }

  .details__view--photo {
    background: rgb(234, 234, 234);
    min-height: 800px;
    min-width: 640px;
  }

  .details__view--category {
    margin-bottom: 30px;
  }

  $applyButtonSize: 110px;

  button {
    background: none;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    color: black;
    display: block;
    text-align: center;
    line-height: 150%;
    height: 50px;
    width: 151px;
    cursor: pointer;
    margin: 30px 25px;

    &:before,
    &:after {
      background: none;
      border: 1px solid black;
      top: -1px;
    }

    &:before {
      border-right: none;
    }

    &:after {
      border-left: none;
    }

    &:hover,
    &.clicked {
      color: $primaryColour;
      border-color: $primaryColour;

      &:before,
      &:after {
        border-color: $primaryColour;
      }
    }
  }

  .modal {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }

  .modal__box {
    background: white;
    min-width: 33%;
    min-height: 360px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 40px;

    input {
      width: 100%;
      padding: 10px;
      font-size: 1.4rem;
    }
  }

  .modal__title {
    font-weight: 800;
  }

  p.modal__textCopied {
    color: $primaryColour;
    margin: 0;
    transition: visibility 0s, opacity 0.3s ease-in-out;
    visibility: hidden;
    opacity: 0;

    &.show {
      visibility: visible;
      opacity: 1;
    }
  }

  .modal__x {
    width: 48px;
    height: 48px;
    top: 0;
    right: 0;
    border-radius: 50%;
    position: absolute;
    z-index: -1;
    cursor: pointer;

    span {
      height: 1px;
      background: black;
      width: 26px;
      z-index: 1000;
      position: absolute;
      vertical-align: middle;
      right: 25%;
      top: 50%;
      // transform: translate(-50%, -50%);

      &.forwardSlash {
        transform: rotate(45deg);
      }

      &.backslash {
        transform: rotate(135deg);
      }
    }
  }
}