@media only screen and (max-width : 1920px) {
  main {
    .hero {
      min-height: 40vw;
    }

    .hero__box {
      right: calc(5% + 1vw);
    }
  }
}

@media only screen and (max-width : 1440px) {
  main {
    .hero {
      min-height: 50vw;
      background-position: 75%;
    }

    .hero__box {
      right: 2vw;

      h1 {
        font-size: 6.8rem;
        max-width: 450px;
      }
    }

    .quotes {
      padding: 100px 0;
    }

    .quotes__blocks {
      flex-direction: column;
      align-items: center;
      margin-bottom: 0;

      blockquote {
        margin: 30px 0;
        max-width: 100%;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .examples {
      h2 {
        font-size: 5.75rem;
      }

      .examples--grid {
        margin-left: 80px;
      }
    }
  }
}

@media only screen and (max-width : 1024px) {
  main {
    margin-top: 63px;

    .hero {
      min-height: 55vw;
      margin: 0 auto;
      margin-top: 393px;
      position: relative;
      background-position: center;
    }

    .hero__box {
      top: calc(-393px + 63px);
      left: 50%;
      transform: translateX(-50%);
      width: max-content;

      h1 {
        font-size: 7.2rem;
        margin: 50px auto;
        margin-bottom: 29px;
        color: $fontColour;
        max-width: 448px;
      }

      button {
        background-color: $primaryColour;
        color: $bgColour;
        margin: 0;

        &:hover {
          color: $primaryColour;
          background-color: $bgColour;
          border-top: 1px solid $primaryColour;
          border-bottom: 1px solid $primaryColour;
          height: $buttonSize;

          &:before,
          &:after {
            background-color: $bgColour;
            border-top: 1px solid $primaryColour;
            border-bottom: 1px solid $primaryColour;
            top: -1px;
          }

          &:before {
            border-left: 1px solid $primaryColour;
          }

          &:after {
            border-right: 1px solid $primaryColour;
          }
        }

        &:before,
        &:after {
          background-color: $primaryColour;
        }
      }
    }

    .links {
      margin-right: 0;
    }

    .quotes {

      h3 {
        padding-top: 0;
        font-size: 2rem;
        font-weight: 900;
      }
    }

    .quotes__blocks {
      flex-direction: column;
    }

    blockquote {
      font-size: 2rem;
      padding: 41px 22px;
    }

    .rating {
      padding: 0;
    }

    .examples {
      .wrapper {
        flex-direction: column;
        padding-bottom: 0;
      }

      h2 {
        max-width: 415px;
        font-size: 6.4rem;
        line-height: 1.05;
        letter-spacing: 0.4px;
        padding: 0;
      }

      button {
        min-width: 200px;
      }

      .examples--grid {
        max-width: 100vw;
        width: 100vw;
        margin-left: 0;
        margin-bottom: 0;
        margin-top: 25px;
      }
    }

    .quotes {
      padding: 59px 0;
    }
  }
}

@media only screen and (max-width : 768px) {
  main {
    .hero {
      min-height: 65vw;
      margin-top: 393px;
      position: relative;
      background-position: center;
    }

    .hero__box {
      width: 100%;
    }

  }

}

@media only screen and (max-width : 480px) {
  main {
    .hero {
      margin-top: calc(63px + 50px + 232px);
    }

    .hero__box {
      top: calc(-232px - 50px);

      h1 {
        font-size: 5rem;
        max-width: 300px;
      }
    }

    .examples {
      h2 {
        max-width: 270px;
        font-size: 4.4rem;
      }
    }
  }
}