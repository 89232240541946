#details {
  @media only screen and (max-width : 1920px) {}

  @media only screen and (max-width : 1440px) {}

  @media only screen and (max-width : 1024px) {

    .title {
      padding-top: 50px;
      padding-bottom: 60px;
    }

    h4 {
      font-size: 3rem;
    }

    h5 {
      font-size: 2rem;
    }
  }

  .details {
    padding: 60px 0;
  }

  .details__view--photo {
    min-height: 0;
    min-width: 0;
  }

  @media only screen and (max-width : 768px) {
    blockquote {
      margin-bottom: 60px;
    }

    .details__view {
      flex-direction: column;

      &>div {
        max-width: 100%;
      }
    }

    .details__view--photo {
      margin: 0;
    }

    .details__view--categories {
      margin-top: 30px;

      h5,
      a,
      p {
        text-align: center;
        display: block;
      }

      button {
        margin: 0 auto;
        margin-top: 50px;
      }
    }
  }

  @media only screen and (max-width : 480px) {
    blockquote {
      font-size: 2.4rem;
    }

    h4 {
      font-size: 2.4rem;
    }

    .subtitle span:last-child {}
  }
}