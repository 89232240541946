.filters {
  display: none;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  text-transform: uppercase;
  font-family: $headingFont;
  font-weight: 800;
  letter-spacing: 1.4px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  margin-top: 18px;
  flex-wrap: wrap;
}

.filter__expanded {
  width: calc(25% - 46px);
  border-right: 1px solid black;
  margin-left: 46px;

  &:last-of-type {
    border: none;
  }


  .filterTitle {
    margin-bottom: 20px;
  }

  li {
    margin: 20px 0;
    display: flex;
    max-width: 260px;
    width: max-content;
    position: relative;

    input {
      display: none;

      &:checked~.filter__x {
        display: block;
        z-index: -1;
      }
    }
  }

  label {
    height: 20px;
    cursor: pointer;
  }

  & div:nth-child(2) {
    margin-top: 55px;
  }

  &.filter__third {
    padding-right: 23px;

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
    }

    .filterTitle {
      width: 100%;
      margin-bottom: 10px;
    }

    li {
      color: transparent;
      width: 25%;
      position: relative;
      margin: 10px 0;

      .filter__option {
        width: 40px;
        height: 40px;

        &:before {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: none;
        }
      }

      .filter__x {
        display: none;
        border: 2px solid black;
        width: 48px;
        height: 48px;
        top: -4px;
        left: -4px;
        border-radius: 50%;
        position: absolute;
        z-index: -1;

        span {
          display: none;
        }
      }

      @import "./filtercolors";
    }
  }
}

.filter__first ul:first-child,
.filter__second ul:first-child {
  margin-bottom: 55px;
}

.filter__apply {
  margin: 35px auto;
  display: block;
}

.filter__option {
  &:before {
    margin-right: 20px;
    width: 20px;
    height: 20px;
    border: 1px solid black;
    content: "";
    display: inline-block;
    vertical-align: middle;
  }
}

.filter__x,
.close {
  display: none;

  span {
    height: 1px;
    background: black;
    width: 26px;
    z-index: 1000;
    position: absolute;
    vertical-align: middle;
    left: -3px;
    top: 50%;

    @media only screen and (-webkit-min-device-pixel-ratio: 2) {
      top: calc(50% + 1px);
    }

    &.forwardSlash {
      transform: rotate(45deg);
    }

    &.backslash {
      transform: rotate(135deg);
    }
  }
}

.controls {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 32px;
}

.close {
  display: block;
  position: relative;
  width: 20px;
  height: 20px;
  margin-left: 31px;
  z-index: 3;
}

.clear {
  border-bottom: 1px solid black;
  width: max-content;
  text-transform: uppercase;
  padding: 7px 0;
}

.clear,
.close {
  cursor: pointer;
}