@media only screen and (max-width : 1440px) {}

@media only screen and (max-width : 1024px) {
  header {
    position: fixed;
    z-index: 100;
    width: 100vw;
    top: 0;
    border-bottom: 1px solid black;
    min-height: 63px;

    .logo {
      margin-left: 25px;
      max-width: 150px;
    }
  }

  .links {
    right: -260px;
    width: 220px;
    height: 100vh;
    top: 0;
    padding-left: 30px;
    background-color: $bgColour;
    position: absolute;
    transition: all 0.5s ease-in-out;
    border-left: 1px solid black;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 70px;
    margin-right: 0;

    a {
      margin: 0 auto;
    }

    button {
      background-color: $bgColour;
      color: $fontColour;
      margin: 0;
      height: 50px;
      text-align: left;

      &:before,
      &:after {
        background-color: $bgColour;
      }
    }

    a:hover,
    button:hover {
      color: $primaryColour;
      border: none;

      &:before,
      &:after {
        border: none;
      }
    }
  }

  .appButton {
    button {
      color: $primaryColour;
    }
  }

  .inspirationButton {
    margin: 14px 0;
    margin-bottom: 26px;
    padding-bottom: 32px;
    border-bottom: 1px solid black;
  }

  .hamburger {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(50% - 5px);
    right: 25px;
    z-index: 10;
    cursor: pointer;
    transform: translateY(-50%);
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

    span {
      background: $fontColour;
      height: 2px;
      width: 20px;
      margin-top: 5px;
      transition: all 0.5s ease-in-out;
    }
  }

  .mobileLogo {
    display: block;
    width: 100%;

    img {
      margin: 0;
      max-width: 80px;
    }
  }

  #hamburger:checked+.hamburger .hamburger__top {
    transform: rotate(-45deg);
    margin-top: 20px;
  }

  #hamburger:checked+.hamburger .hamburger__bottom {
    opacity: 0;
    transform: rotate(45deg);
  }

  #hamburger:checked+.hamburger .hamburger__middle {
    transform: rotate(45deg);
    margin-top: -2.5px;
  }

  #hamburger:checked~.links {
    right: 0;
  }
}

@media only screen and (max-width : 768px) {
  header {
    .logo {
      max-width: 113px;
      ;
    }
  }
}

@media only screen and (max-width : 480px) {}