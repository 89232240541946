main {
  margin-top: 100px;

  .hero {
    background-image: url('../assets/images/Lookscope-hero-photo.jpg');
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 40vw;
    position: relative;
    text-align: center;
    width: 100%;
  }

  @media (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi) {
    .hero {
      background-image: url('../assets/images/Lookscope-hero-photo-w4100px.jpg');
    }
  }

  .hero__box {
    position: absolute;
    right: 13%;
    top: 50%;
    transform: translateY(-50%);

    button {
      background-color: $bgColour;
      color: $primaryColour;
      width: calc(291px - 50px);
      margin-top: 40px;

      &:before,
      &:after {
        background-color: $bgColour;
      }

      &:hover {
        background-color: $primaryColour;
        color: $bgColour;

        &:before,
        &:after {
          background-color: $primaryColour;
        }
      }
    }
  }

  .quotes {
    padding: 150px 0;
  }

  .quotes__blocks {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin: 50px auto;
    margin-bottom: 0;
  }

  h3 {
    position: relative;
    margin-bottom: 30px;
    letter-spacing: 2px;
    width: max-content;
    margin: 0 auto;
    border-bottom: 3px solid black;
    padding-bottom: 15px;
  }

  blockquote {
    font-size: 2.48rem;
    border: 1px solid black;
    padding: 52px 67px;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: 0;
    max-width: 600px;
  }

  .blockquote--author {
    font-weight: 800;
    font-size: 1.4rem;
  }

  .rating {
    display: flex;
    flex-direction: row;
    font-size: 1.6rem;
    padding: 0 150px;
    position: relative;
    margin: auto;

    &:before {
      content: "";
      width: 184px;
      height: 1px;
      background-color: black;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 20px;
    }
  }

  .star {
    margin: 20px 2.5px;
    margin-top: 45px;
    font-size: 2.4rem;
  }

  .blockquote--author {
    text-transform: uppercase;
    font-size: 1.4rem;
  }

  .examples {
    border-top: 1px solid black;

    .wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      padding: 53px 0;
    }

    .examples--title {
      margin: auto;
    }

    button {
      margin: 25px auto;
      display: block;
      min-width: 200px;
    }

    h2 {
      letter-spacing: 0.78px;
      line-height: 80px;
      font-size: 7.8rem;
      text-align: center;
      text-transform: none;
      font-weight: 900;
      max-width: 500px;
    }

    .examples--grid {
      display: grid;
      grid-template-columns: 25% 25% 25% 25%;
      grid-template-rows: 25% 25% 25% 25%;
      margin-left: 100px;
      max-width: 50%;
    }
  }
}