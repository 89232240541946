@media only screen and (max-width : 1440px) {
  footer {
    .footer--container {
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;

      .logo {
        margin-right: 0;
      }

      .resources,
      .other {
        margin: 0 20px;
        width: 25%;
        max-width: 118px;
      }

      .social {
        margin: 25px 100px;
        margin-right: 0;
        margin-top: 0;
        width: 25%;

        a {
          max-width: 37px;
        }
      }

      .social__container {
        margin: 0;
      }
    }
  }
}

@media only screen and (max-width : 1024px) {
  footer {
    padding-top: 50px;
    margin: 0;

    .footer--container {
      width: 100%;

      .logo {

        img {
          width: 80px;
          margin: 0;
        }
      }

      .social {
        margin: 0;
      }

      .social__container {

        align-items: top;

        a {
          min-height: 37px;
          margin: auto 8px;
          width: 37px;

          &:first-child {
            margin-left: 0;
          }

          &:nth-child(3) {
            margin-right: 0;
          }

          &:last-child {
            min-width: 100%;
            margin: 0;
            margin-top: 15px;
          }
        }
      }

      .resources,
      .other {
        margin: 0;
        min-height: 124px;

        a:last-child {
          margin-bottom: 0;
        }
      }

      .other {
        margin-right: 65px;
      }
    }

    .copyright {
      margin: 0;
      padding: 10px 0;
      margin-top: 50px;

      p {
        margin: 0;
      }
    }
  }
}

@media only screen and (max-width : 768px) {
  footer {
    .footer--container {
      flex-wrap: wrap;

      .logo {
        max-width: 100%;
        width: 100%;
        height: initial;
      }

      .social {
        margin-right: auto;
        margin-top: 56px;
        min-width: 171px;
        height: max-content;

        a {
          min-height: 32px;
          margin: 0 10px;
          width: 32px;

          &:last-child {
            min-width: 170px;
            margin-right: 0;
          }
        }
      }

      .resources,
      .other {
        min-width: 118px;
        max-width: 50%;
        margin-top: 48px;
        margin-bottom: 0;
        margin-right: 0;
      }

      .resources {
        margin-right: 120px;
      }
    }
  }
}

@media only screen and (max-width : 480px) {
  footer {
    .footer--container {

      &.wrapper {
        max-width: 300px
      }

      .resources,
      .other {
        margin-top: 48px;
      }

      .resources {
        margin-right: 0;
      }

      .social__container {
        width: 100%;
        max-width: 100%;
      }

      .social {
        width: 100%;
        max-width: 100%;

        a {
          min-height: 27px;
          width: 27px;

          &:last-child {
            min-width: 137px;
            margin-top: 0;
            margin-left: 42px;
          }
        }
      }
    }

    .copyright {
      p:last-child {
        padding-bottom: 40px;
      }
    }
  }
}