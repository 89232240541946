header {
  background: white;
  min-height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  // box-shadow: 1px 1px 20px darkgrey;
  border-bottom: 1px solid black;
  
  button {
    margin-left: 0;
  }

  .button {
    margin: 0;
  }

  .logo {
    width: 141px;
    display: flex;
    margin-left: 40px;
  
    a {
      margin: auto;
    }
  }
}

#hamburger {
  display: none;
}

nav {
  display: flex;
  position: relative;
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  margin-right: 40px;
}

.inspirationButton {
  font-size: 1.4rem;
  font-weight: 900;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin: auto;

  a {
    width: 152px;

    &:hover {
      color: $primaryColour;
    }
  }
}

.mobileLogo {
  display: none;
}