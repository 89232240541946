@import url('https://fonts.googleapis.com/css?family=Nunito:400,800,900&display=swap');

$primaryColour: #FF4500;
$secondaryColour: #CCCCCC;
$bgColour: #FFFFFF;
$tertiaryColour: #999999;
$fontColour: #000000;

$headingFont: 'Nunito',
sans-serif;
$contentFont: $headingFont;

$fontSize: 10px;