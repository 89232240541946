#inspirations {
  @media only screen and (max-width : 1920px) {}

  @media only screen and (max-width : 1440px) {
    .back {
      margin-left: 0;
    }

    .outfits {
      grid-template-columns: calc((100%/3) - 53.5px) calc((100%/3) - 53.5px) calc((100%/3) - 53.5px);
    }

    .filter__expanded.filter__third li {
      width: calc(100%/3);
    }
  }

  @media only screen and (max-width : 1024px) {

    .controls {
      width: 50%;
      margin-top: 0;
      margin-right: 20px;
    }

    #filterToggle:checked~.filters {
      padding-top: 40px;
      border: 1px solid black;
      margin-bottom: 20px;
    }

    .filter__apply {
      order: -1;
      margin: 0;
      left: 50%;
      transform: translateX(-50%);

      &.mobile {
        display: block;
        order: initial;
        margin: 40px 0;
      }
    }

    .filter__expanded {
      width: calc(50% - 46px);
      padding-bottom: 40px;

      &.filter__third li {
        width: 25%;
      }
    }

    .filter__second {
      border-right: none;
    }

    .outfits {
      grid-template-columns: calc((100%/3) - 27.5px) calc((100%/3) - 27.5px) calc((100%/3) - 27.5px);
      grid-gap: 40px;
    }
  }

  @media only screen and (max-width : 768px) {
    margin-top: 123px;

    .back {
      margin-bottom: 40px;

      svg {
        width: 50px;
      }

      a {
        width: 220px;
      }
    }

    .filterButton {
      margin: 0 auto;
    }

    h2 {
      font-size: 24px;
      letter-spacing: 2px;
      font-weight: 800;
      padding-bottom: 12px;
    }

    .filter__expanded.filter__third {
      padding-bottom: 0;

      li {
        width: calc(100%/3);
      }
    }

    .outfits {
      grid-template-columns: calc(50% - 10px) calc(50% - 10px);
      grid-gap: 20px;
    }
  }

  @media only screen and (max-width : 480px) {

    .filterText {
      display: none;

      &~span {
        display: none;
      }
    }

    .filterToggle {
      position: relative;
    }

    #filterToggle:checked~.filters {
      width: 100%;
      position: absolute;
      background: white;
      z-index: 3;
      top: 35px;
    }

    .filter__expanded {
      width: 100%;
      border-right: none;

      &.filter__third {
        padding-bottom: 40px;

        li {
          width: 25%;
        }
      }

      &.filter__fourth {
        padding-bottom: 0;
      }
    }

    .filter__apply {
      margin: 0;
      width: 110px;
      left: 30px;
      transform: none;

      &.mobile {
        left: 0;
        margin: 40px auto;
      }
    }

    .controls {
      margin-bottom: 55px;
    }

    .close {
      margin-left: 20px;
    }
  }

  @media only screen and (max-width : 480px) {
    .back {
      svg {
        width: 40px;
      }
    }
  }
}