#inspirations {
  @import "./filters";

  margin-top: 200px;
  margin-bottom: 100px;



  .title {
    margin-bottom: 25px;
  }

  .feedback {
    margin-bottom: 70px;
  }

  h5 {
    letter-spacing: 2px;
    font-size: 14px;

    &:hover a {
      color: $primaryColour;
    }
  }

  h2 {
    font-size: 38px;
    letter-spacing: 3.8px;
    width: max-content;
    margin: 0 auto;
    padding-bottom: 20px;
    border-bottom: 3px solid black;
    padding-top: 0;
  }

  .outfits {
    margin-top: 20px;
    display: grid;
    grid-template-columns: calc((100% / 3) - 50px) calc((100% / 3) - 50px) calc((100% / 3) - 50px);
    grid-gap: 80px;
    justify-items: center;
  }

  .continueSearch {
    margin: 0 auto;
    margin-top: 50px;
    display: block;

    &:hover {
      color: $primaryColour;
      border-color: $primaryColour;

      &:before,
      &:after {
        border-color: $primaryColour;
      }
    }
  }

  .filterToggle {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .noResults {
    font-size: 14px;
    text-transform: uppercase;
    font-family: "Nunito", sans-serif;
    font-weight: 800;
    letter-spacing: 1.4px;
    text-align: center;
    margin-top: 40px;
  }

  .filterButton,
  .filter__apply,
  .continueSearch {
    background: none;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    color: black;
    display: block;
    text-align: center;
    line-height: 150%;
    cursor: pointer;

    &:before,
    &:after {
      background: none;
      border: 1px solid black;
      top: -1px;
    }

    &:before {
      border-right: none;
    }

    &:after {
      border-left: none;
    }
  }

  $applyButtonSize: 110px;

  .filter__apply {
    padding: 7.5px 0;
    margin: 36px auto;
    font-weight: 600;
    height: $applyButtonSize/2.75;
    width: $applyButtonSize;
    font-size: 12px;

    &.mobile {
      display: none;
    }

    &:before,
    &:after {
      height: $applyButtonSize/2.75;
      width: $applyButtonSize/2.5;
    }

    &:before {
      left: -$applyButtonSize/5;
      border-right: none;
    }

    &:after {
      right: -$applyButtonSize/5;
      border-left: none;
    }

    &:hover {
      color: $primaryColour;
      border-color: $primaryColour;

      &:before,
      &:after {
        border-color: $primaryColour;
      }
    }
  }

  $filterButtonSize: 100px;

  .filterButton {
    padding: 8.5px 0;
    padding-left: 5px;
    margin: 0 36px;
    height: $filterButtonSize/2.5;
    width: $filterButtonSize;
    font-size: 12px;
    display: flex;
    align-items: center;

    &:before,
    &:after {
      height: $filterButtonSize/2.5;
      width: $filterButtonSize/2.5;
    }

    &:before {
      left: -$filterButtonSize/4;
      border-right: none;
    }

    &:after {
      right: -$filterButtonSize/4;
      border-left: none;
    }

    &:hover span:before,
    &:hover span:after {
      background: black;
    }

    span {
      position: relative;
      text-align: center;
      padding: 0 6px;
      margin-left: 7px;
      margin-bottom: 6px;
      height: 10px;
      left: 5px;
      top: 35%;

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        height: 3px;
        background-color: black;
        transition: transform 0.2s ease-in-out;
      }

      &:before {
        right: 0;
        width: 51%;
        transform: skew(0deg, -40deg);
      }

      &:after {
        left: 0;
        width: 50%;
        transform: skew(0deg, 40deg);
      }
    }
  }

  #filterToggle {
    display: none;

    &:checked {
      &~.filters {
        top: 0;
        display: flex;
      }

      &~.filterButton span {
        &:before {
          transform: skew(0deg, 40deg);
        }

        &:after {
          transform: skew(0deg, -40deg);
        }
      }
    }
  }
}